/* eslint-disable no-useless-escape */
import {
  DEVICE_IMAGE_VERSION_MESSAGE,
  DISPLAY_NAME_MESSAGE,
} from "../admin-actions/adminActionsConstants";

import {
  DISPLAY_NAME_MESSAGE_RESTRICTION,
  NAME_LIMIT_CHARACTERS,
  NAME_MIN_LENGTH,
} from "../admin-actions/adminActionsConstants";
import { AD_DOMAIN_CONTROLLER_USAGE_TYPES } from "../onboarding/onboardingConstants";
export const composeValidators =
  (...validators) =>
  (value, value2) =>
    validators.reduce(
      (error, validator) => error || validator(value, value2),
      undefined,
    );

export function required(value) {
  return value ? undefined : "This field is required";
}

export function noBlankSpaces(value) {
  return value === null || value.match(/^ *$/) !== null
    ? "This field can not be empty"
    : undefined;
}

export const domainControllerValidator =
  (activeDirectoryDomainController) => (v) => {
    return v
      ? undefined
      : activeDirectoryDomainController ===
          AD_DOMAIN_CONTROLLER_USAGE_TYPES.AZURE_AD_DIRECT_JOIN
        ? undefined
        : "This field is required";
  };

export function greaterThanZero(value) {
  return value && value > 0 ? undefined : "Value should be greater than zero";
}

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const EMAIL_DOMAIN_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@netapp.com/;
export const MOBILE = /^\+?\d{0,3}\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
export const NO_SPACES_AND_SPECIAL_CHARACTERS = /^(\d|\w|-)+$/;
export const NO_SPACES_NO_UNDERSCORE_AND_SPECIAL_CHARACTERS =
  /^(?=.{1,255}$)[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/;
export const VERSION_NUMBER_REGEX = /^(\d+\.)(\d+\.)(\*|\d+)$/;
export const IP_ADDRESS_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const URL_REGEX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export const URL_VSPHERE =
  /^(https?:\/\/)((([01]?\d?\d|2[0-4]\d|25[0-5])\.){3}([01]?\d?\d|2[0-4]\d|25[0-5])|([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,6})(:[\d]{1,5})?(\/[\w .-]*)*\/?$/;
export const VALID_DOMAIN =
  /^((?!-)[A-Za-z0-9-]{1, 63}(?<!-)\\.)+[A-Za-z]{2, 6}$/;

export const isValidCpin = (cpin) => {
  return !cpin || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8}$/.test(cpin);
};

export function isValidEmail(email) {
  return EMAIL_REGEX.test(email) ? true : false;
}

export function checkValidUrl(url) {
  return URL_REGEX.test(url) ? undefined : "Invalid url provided";
}

export function checkValidUrlVsphere(url) {
  return URL_VSPHERE.test(url)
    ? undefined
    : "The URL must start with 'http(s)://' and must be followed by a valid IP address or FQDN.";
}

export function isValidDomainName(v) {
  return VALID_DOMAIN.test(v) ? true : false;
}

export function isURL(v) {
  return URL_REGEX.test(v) ? true : false;
}

export function isIPAddress(v) {
  return IP_ADDRESS_REGEX.test(v) ? true : false;
}

export function isProfilePath(v) {
  return v && v.length > 1 && v.substring(0, 2) === "\\\\" ? true : false;
}

export function checkValidProfilePath(v) {
  return v && v.length > 1 && v.substring(0, 2) === "\\\\"
    ? undefined
    : "Invalid profile path";
}

export function isValidDomain(email) {
  return EMAIL_DOMAIN_REGEX.test(email) ? true : false;
}

export function checkEmail(email) {
  return isValidEmail(email) ? undefined : "Invalid email provided";
}

export function checkProfilePath(v) {
  return v.length > 1 && v.substring(0, 2) === "\\\\"
    ? undefined
    : "Invalid Profile Path Should start with \\\\";
}

export function checkProfilePathSpotGroup(v) {
  return v &&
    v.profilePath &&
    v.profilePath.length > 1 &&
    v.profilePath.substring(0, 2) === "\\\\"
    ? undefined
    : "Invalid Profile Path";
}

export function checkAddressRangeSuffix(v) {
  return Number(v) >= 1 && Number(v) <= 32
    ? undefined
    : "Value must be between 1 and 32";
}

export function checkAddressRangeSuffixValue(v) {
  return Number(v) >= 1 && Number(v) <= 32 ? true : false;
}

export function checkSupportEmail(email) {
  return isValidEmail(email)
    ? isValidDomain(email)
      ? undefined
      : "Invalid email provided. Only @netapp.com emails are valid"
    : "Invalid email provided";
}

export function checkMobile(mobile) {
  return MOBILE.test(mobile.replace(/[^\d+]/g, ""))
    ? undefined
    : "Invalid mobile number provided";
}

export function checkInt(v) {
  return Number.isInteger(v) ? undefined : "invalid integer";
}

export function greaterThanThree(value) {
  return checkInt(value) && value >= 3
    ? undefined
    : "Value should be greater than or equal to three";
}

export function checkImageName(v) {
  return v && NO_SPACES_AND_SPECIAL_CHARACTERS.test(v) && !v.includes("-")
    ? undefined
    : DISPLAY_NAME_MESSAGE;
}

export function criticalValueValidation(value, condition, warningValue) {
  if (condition === "LT") {
    return value && warningValue && parseFloat(value) < parseFloat(warningValue)
      ? undefined
      : `Value should be less than ${warningValue}`;
  }

  return value && warningValue && parseFloat(value) > parseFloat(warningValue)
    ? undefined
    : `Value should be greater than ${warningValue}`;
}

export function checkSiteName(v) {
  return v && NO_SPACES_NO_UNDERSCORE_AND_SPECIAL_CHARACTERS.test(v)
    ? undefined
    : DISPLAY_NAME_MESSAGE_RESTRICTION;
}

export function checkVersionNumberFormat(v) {
  return v && VERSION_NUMBER_REGEX.test(v)
    ? undefined
    : DEVICE_IMAGE_VERSION_MESSAGE;
}

export function checkIPAddressFormat(v) {
  return v && IP_ADDRESS_REGEX.test(v) ? undefined : "Invalid IP Address";
}

export function checkNullOrIPAddressFormat(v) {
  return !v
    ? undefined
    : v && IP_ADDRESS_REGEX.test(v)
      ? undefined
      : "Invalid IP Address";
}

export function checkValidURLFormat(v) {
  return !v ? undefined : v && URL_REGEX.test(v) ? undefined : "Invalid IP URL";
}

export function checkPath(v) {
  return v.length > 1 && v.substring(0, 2) === "\\"
    ? undefined
    : "Invalid Profile Path";
}

export function checkValidDomainName(v) {
  return VALID_DOMAIN.test(v) ? undefined : "Invalid Domain Name";
}

export const checkMaxLength =
  (maxLength = 15) =>
  (v) => {
    return !v || (v?.length >= 0 && v?.length <= maxLength)
      ? undefined
      : `Invalid Input, should be less than ${maxLength}`;
  };

export const checkNameLength =
  (minLength = NAME_MIN_LENGTH, maxLength = NAME_LIMIT_CHARACTERS) =>
  (v) => {
    return !v || (v.length >= minLength && v.length <= maxLength)
      ? undefined
      : DISPLAY_NAME_MESSAGE_RESTRICTION.replace(
          NAME_MIN_LENGTH,
          minLength,
        ).replace(NAME_LIMIT_CHARACTERS, maxLength);
  };

export const checkExistingSite = (sites) => (v) => {
  let siteExists = false;
  sites.map((a) => {
    if (a.name === v) {
      siteExists = true;
    }
  });
  return siteExists ? "Site already exists!" : undefined;
};

export const checkExistingPool = (pools) => (v) => {
  if (!pools || !Array.isArray(pools)) {
    return undefined;
  }

  const poolExists = pools.some((a) => a?.name === v);

  return poolExists ? "Pool already exists!" : undefined;
};

export const checkIpAddressExistsPrefix = (usedIpRanges, suffix) => (v) => {
  return usedIpRanges?.find((ip) => ip === v + "/" + suffix)
    ? "This IP address range is already taken."
    : undefined;
};

export const checkIpAddressExistsSuffix = (usedIpRanges, prefix) => (v) => {
  return usedIpRanges?.find((ip) => ip === prefix + "/" + v) ? " " : undefined;
};

export const checkMaxConcurrentChangeValue = (v) => {
  return parseInt(v) > 0
    ? undefined
    : "Invalid Input, should be greater than 0";
};

export const checkCustomNetBIOSNameFormat = (v) => {
  return v?.length === 0 || NO_SPACES_AND_SPECIAL_CHARACTERS.test(v)
    ? undefined
    : "Invalid NetBIOS name";
};

export function releaseAnnouncementEndTimeValidator(value, formValues) {
  if (value && formValues.startTime) {
    const d1 = new Date(formValues.startTime);
    const d2 = new Date(value);
    return d2.getTime() > d1.getTime()
      ? undefined
      : "End time should be later to start time";
  }
}
export function releaseAnnouncementStartTimeValidator(value, formValues) {
  if (formValues.endTime && value) {
    const d1 = new Date(value);
    const d2 = new Date(formValues.endTime);
    return d2.getTime() > d1.getTime()
      ? undefined
      : "Start time should be earlier to end time";
  }
}

export function validateSiteName(
  siteName = "",
  minLength = NAME_MIN_LENGTH,
  maxLength = NAME_LIMIT_CHARACTERS,
) {
  const hasSpecialCharacters = !!checkSiteName(siteName);
  const isOversized = !!checkNameLength(minLength, maxLength)(siteName);
  const isValid = !(!siteName || hasSpecialCharacters || isOversized);
  return isValid;
}
