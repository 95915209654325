import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import moment from "moment";
import React from "react";

export const APP_NAME = "HP Anyware";

export const GROUPS_API_SIZE = 200;
export const PROXY_API_URL = import.meta.env.REACT_APP_PROXY_API_URL;

export const WEBSOCKET_SERVER_URL = import.meta.env
  .REACT_APP_PROXY_API_WEBSOCKET_SERVER;
export const WEBSOCKET_SERVER_DOMAIN = import.meta.env
  .REACT_APP_PROXY_API_WEBSOCKET_SERVER_DOMAIN;

// Azure Marketplace Offer URL user will be redirected to when coming from Workforce Experience referral page
export const HP_ANYWARE_AZURE_MARKETPLACE_OFFER_URL = import.meta.env
  .REACT_APP_HP_ANYWARE_AZURE_MARKETPLACE_OFFER_URL;

export const DEFAULT_FORCE_SESSION_TERMINATION_SPOT_GROUPS = 5;

export const ZENDESK_CHAT_BOT_URL = import.meta.env
  .REACT_APP_ZENDESK_CHAT_BOT_URL;

export const MANUALLY_CONFIGURING_TEMPLATE_VM =
  "Manually Configuring Template VM";
export const FAILED_CONFIGURING_TEMPLATE_VM = "Error Creating Template";

export const SESSION_INACTIVITY_TIMEOUT = 6 * 60 * 60; // seconds
export const SESSION_INACTIVITY_RESPONSE = 2 * 60; // seconds

export const START_FUNCTIONS_STEP = 4;
export const CONDUCTOR_INSTALLED_STEP = "conductor installed";

// TODO: define the destination of the release notes
export const RELEASE_NOTES_URL = "";

export const GLOBAL_SITE = "Global";

export const HP_WORKFORCE_INTEGRATION_INSTRUCTIONS = `Integration with HP Workforce Experience requires retrieving your organization’s Company PIN located 
under Preferences in Settings in the HP Workforce Experience platform. Once enrolled, you can view your telemetry data 
for HP Anyware Manager Enterprise.`;

export const FREEZE_COLUMNS_ALLIGNMENT = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};
export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const LAYOUTS = {
  FULL_SCREEN: "fullScreen",
  MENU_SCREEN: "menuScreen",
};

export const PROGRESS_STATUS = {
  COMPLETED: "succeeded",
  IN_PROGRESS: "started",
  PENDING: "Pending",
  FAILED: "failed",
  NOT_STARTED: "not started",
  SKIPPED: "skipped",
};

export const TENANT_PROGRESS = {
  COMPLETED: "succeeded",
  IN_PROGRESS: "started",
  PENDING: "not started",
  FAILED: "failed",
  NOT_STARTED: "not started",
  SKIPPED: "skipped",
};

export const BOOLEAN_OPTIONS = {
  YES: "true",
  NO: "false",
};

export const BOOLEAN_RADIO_OPTIONS = [
  {
    label: "Yes",
    value: BOOLEAN_OPTIONS.YES,
  },
  {
    label: "No",
    value: BOOLEAN_OPTIONS.NO,
  },
];

export const SEVERITY_LEVELS = {
  High: "High",
  Medium: "Medium",
  Low: "Low",
};

export const STATUS_TYPES = {
  Active: "Active",
  InActive: "InActive",
  DeleteFailed: "DeleteFailed",
  CreateFailed: "CreateFailed",
  Error: "Error",
  Creating: "Creating",
  Pending: "Pending",
  Deleting: "Deleting",
  Available: "Available",
  Preparing: "Preparing",
};

export const CI_STATUS_TYPES = {
  ALL: "All",
  ACTIVE: "Active",
  RESOLVED: "Resolved",
};

export const CI_ALERTS_SEVERITY_TYPES = {
  CRITICAL: "Critical",
  NORMAL: "Normal",
  WARNING: "Warning",
};

export const CI_ALERTS_SEVERITY_TYPES_ICON_MAPPING = {
  WARNING: (
    <WarningIcon
      fontSize="inherit"
      style={{ marginRight: "4px", color: "#FFA726" }}
    />
  ),
  CRITICAL: (
    <CancelIcon
      fontSize="inherit"
      style={{ marginRight: "4px", color: "#FF0000" }}
    />
  ),
};

export const CI_ALERTS_SEVERITY_IN_CI_MAP = {
  critical: "CRITICAL",
  normal: "NORMAL",
  warning: "WARNING",
};

export const MONITOR_STATUSES = {
  ACTIVE: {
    displayValue: "Active",
    apiKey: "resume",
    key: "ACTIVE",
  },
  PAUSED: {
    displayValue: "Paused",
    apiKey: "pause",
    key: "PAUSED",
  },
};

export const STATUS_UPDATE_SUBJECTS = {
  MACHINE: "ServerStatusUpdated",
  RESOURCE_POOL: "ResourcePoolStatusUpdated",
  SITE: "SiteStatusUpdated",
  USER_SESSIONS: "UserUpdate",
};

export const SOCKET_ENTITIES = {
  TENANT_RESOURCE_STATUS_UPDATES: "tenantResourceStatusUpdates",
  GLOBAL_NOTIFICATIONS: "globalNotifications",
  TENANT_ONBOARDING_NOTIFICATIONS: "tenantOnboardingNotifications",
  GLOBAL_ALERT_NOTIFICATIONS: "globalAlertsNotifications",
  RELEASE_ANNOUNCEMENTS: "releaseAnnouncements",
};

export const CI_STATUS_TYPES_OPTIONS = Object.keys(CI_STATUS_TYPES).map(
  (i) => ({
    name: i,
    value: CI_STATUS_TYPES[i],
  }),
);
export const ALL_CUSTOMERS = "All Tenants";

export const TENANT_ID_PREFIX = "/tenants/";
export const CUSTOM_ACTION_ID_PREFIX = "/customActions/";
export const API_ACCOUNT_ID_PREFIX = "/apiUsers/";
export const NOTIFICATION_PROFILE_ID_PREFIX = "/notificationProfiles/";
export const RESOURCE_ID_PREFIX = "/spotGroups/";
export const ORGANIZATION_ID_PREFIX = "/organizations/";
export const IDENTITY_ID_PREFIX = "/identities/";
export const INVITATION_ID_PREFIX = "/invitations/";
export const SNACKBAR_DURATION_MS = 6000;
export const LOADING_TEXT = "Loading...";

export const RDP_PROPERTIES = {
  diskDrives: "Disk drives",
  comPorts: "Com ports",
  clipboard: "Clipboard",
  printers: "Printers",
  smartCards: "Smart cards",
  audioInput: "Audio input",
  audioOutput: "Audio output",
  fullScreenMode: "Full screen mode",
  cameras: "Cameras",
  devices: "Devices",
  usbDevices: "Usb devices",
  multiMonitor: "Multi monitor",
};

export const TABLE_SIZE_KEY = "tableSize";

export const COMPONENT_NAMES = {
  ADD_TENANT: "add_tenant",
  TENANT_ACTIONS: "tenant_actions",
  ORG_DETAIL: "org_detail",
};

export const ZENDESK_ELIGIBLE_ROLES = [
  "Organization Administrator",
  "Organization User",
  "Tenant Creator",
  "Tenant Administrator",
];

export const ORG_ROLES = {
  ORG_ADMIN: "Organization Administrator",
  ORG_USER: "Organization User",
  TENANT_CREATOR: "Tenant Creator",
  TENANT_ADMIN: "Tenant Administrator",
};

export const DEFAULT_PERMISSIONS = {
  "Organization Administrator": [
    COMPONENT_NAMES.ADD_TENANT,
    COMPONENT_NAMES.ORG_DETAIL,
    COMPONENT_NAMES.TENANT_ACTIONS,
  ],
  "Organization User": [],
  "Tenant Creator": [
    COMPONENT_NAMES.ADD_TENANT,
    COMPONENT_NAMES.ORG_DETAIL,
    COMPONENT_NAMES.TENANT_ACTIONS,
  ],
  "Tenant Administrator": [
    COMPONENT_NAMES.ADD_TENANT,
    COMPONENT_NAMES.ORG_DETAIL,
    COMPONENT_NAMES.TENANT_ACTIONS,
  ],
};

export const ROLES_WITH_EDITABLE_PERMISSIONS = {
  [ORG_ROLES.ORG_USER]: true,
  [ORG_ROLES.TENANT_CREATOR]: true,
};

export const SUPPORT_ROLES = ["Support Administrator", "Support User"];
export const SUPPORT_ADMIN = "Support Administrator";
export const USER_TYPES = {
  SUPPORT_USER: "supportUser",
  NORMAL_USER: "normalUser",
};

export const CI_TIME_SPAN_OPTIONS = [
  { name: "Last 15 Minutes", value: "FIFTEEN_MINUTES" },
  { name: "Last 30 Minutes", value: "THIRTY_MINUTES" },
  { name: "Last 60 Minutes", value: "ONE_HOUR" },
  { name: "Last 2 Hours", value: "TWO_HOURS" },
  { name: "Last 3 Hours", value: "THREE_HOURS" },
  { name: "Last 6 Hours", value: "SIX_HOURS" },
  { name: "Last 12 Hours", value: "TWELVE_HOURS" },
  { name: "Last 24 Hours", value: "ONE_DAY" },
  { name: "Past 48 Hours", value: "TWO_DAYS" },
  { name: "Past 1 Week", value: "ONE_WEEK" },
  { name: "Past 30 Days", value: "THIRTY_DAYS" },
];

export const RETRY_TIME_FOR_LOADING_CHUNK = 300;

export const TABLE_KEYS = {
  ROLES_LIST: "rolesList",
  TENANTS_LIST: "tenantsList",
  DASHBOARD_LICENSES: "licensesListInDashboard",
  USER_SESSIONS_LIST: "userSessionsList",
  USER_SESSIONS_ACTIVE_PROCESSES: "userSessionsActiveProcesses",
  MACHINES_LIST: "machinesList",
  DATA_VOLUMES_LIST: "dataVolumesList",
  SECURITY_ALERTS_LIST: "securityAlertsList",
  RECOMMENDATIONS_LIST: "recommendationsList",
  CI_ALERTS_LIST: "ciAlertsList",
  IMAGE_SETS_LIST: "imageSetSList",
  IMAGE_SET_VERSIONS_LIST: "imageSetVersionsList",
  SPOT_GROUP_LIST: "spotGroupList",
  CONNECTIONS_LIST: "connectionsList",
  DEVICE_IMAGE_LIST: "deviceImagelist",
  PROVISIONING_POLICIES_LIST: "provisioningPoliciesList",
  API_ACCOUNTS_LIST: "apiAccountsList",
  ADMIN_ACCOUNTS_LIST: "adminAccountsList",
  BUSINESS_SERVERS_LIST: "businessServersList",
  MACHINE_DETAILS_PROCESSES: "machineDetailsProcesses",
  MACHINES_DETAILS_ACTIVE_SESSIONS: "machineDetailsActiveSessions",
  ADMIN_USERS_LIST: "adminUsersList",
  SITES_LIST: "sitesList",
  RELEASE_ANNOUNCEMENTS: "releaseAnnouncements",
  AFFECTED_RESOURCES: "affectedResources",
  RELATED_ENTITIES: "relatedEntities",
  FILE_ENTITIES: "fileEntities",
  HOST_ENTITIES: "hostEntities",
  PROCESS_ENTITIES: "processEntities",
  SUPPORT_ADMIN_CONTAINER: "supportAdminContainer",
  CI_ALERTS_MONITORS: "ciAlertsMonitors",
  USER_LOGON_ACTIVITY_FOR_USER_SESSION: "userLogonActivityUserSession",
  USER_LOGON_ACTIVITY_FOR_MACHINE: "userLogonActivityMachine",
  USER_LOGON_ACTIVITY_FOR_TENANT: "userLogonAvtivityTenant",
  SPOT_GROUP_IMAGE_SET_HISTORY: "spotGroupImageSetHistory",
  NOTIFICATION_PROFILES: "notificationProfiles",
  CUSTOM_ACTIONS: "customActions",
};

export const ACCORDIAN_KEYS = {
  ORGANIZATION_DETAILS: "orgDetails",
  TENANT_DETAILS: "tenantDetails",
  ADMINS_LIST: "adminsList",
  ADMIN_ACTIVITY_LOGS: "adminActivityLogs",
  ALERT_DETAILS: "alertDetails",
};

export const LOGS_CONFIG_KEYS = {
  TENANT_LOGS: "tenantLogs",
  TENANT_ADMIN_ACTIVITY_LOGS: "tenantAdminActivityLogs",
  ORCHESTRATION_LOGS: "orchestrationLogs",
  USER_LOGON_ACTIVITY_FOR_USER_SESSION: "userLogonActivityForUserSessionV2",
  USER_LOGON_ACTIVITY_FOR_MACHINE: "userLogonActivityForMachineV2",
  USER_LOGON_ACTIVITY_FOR_TENANT: "userLogonAvtivityForTenantV2",
  CI_ALERTS_CONFIG: "ciAlertsConfig",
  ALL_ADMIN_ACTIVITY_LOGS: "allAdminActivityLogs",
};
export const PERMISSIONS_TYPE = {
  CREATE: "create",
  EDIT: "update",
  DELETE: "delete",
  VIEW: "read",
  ACCESS: "access",
};

export const FULL_ACCESS_PERMISSION = "FULL_ACCESS";

// map of strings that backend response will return
export const MODULES_LIST = {
  DASHBOARD: "DASHBOARD",
  OVERVIEW: "OVERVIEW",
  TENANT_DETAILS: "TENANT_DETAILS",
  LICENSES: "LICENSES",
  USER_SESSIONS: "USER_SESSIONS",
  PERFORMANCE: "PERFORMANCE",
  PROCESSES: "PROCESSES",
  USER_LOGON_ACTIVITY: "USER_LOGON_ACTIVITY",
  QUICK_ACTIONS: "QUICK_ACTIONS",
  LOGOFF: "LOGOFF",
  VIRTUAL_MACHINES: "VIRTUAL_MACHINES",
  ACTIVE_SESSIONS: "ACTIVE_SESSIONS",
  CONNECT_TO_MACHINE: "CONNECT_TO_MACHINE",
  CONNECT_AS_TO_MACHINE: "CONNECT_AS_TO_MACHINE",
  REBOOT: "REBOOT",
  DATA_VOLUMES: "DATA_VOLUMES",
  SUMMARY: "SUMMARY",
  DIRECTJOIN_PROPERTIES: "DIRECTJOIN_PROPERTIES",
  SECURITY: "SECURITY",
  SECURITY_ALERTS: "SECURITY_ALERTS",
  RECOMMENDATIONS: "RECOMMENDATIONS",
  LOGS: "LOGS",
  ADMIN_ACTIVITY: "ADMIN_ACTIVITY",
  ALERTS: "ALERTS",
  IMAGES: "IMAGES",
  SPOT_GROUPS: "SPOT_GROUPS",
  CONNECTIONS: "CONNECTIONS",
  DEVICE_IMAGES: "DEVICE_IMAGES",
  PROVISIONING_POLICY: "PROVISIONING_POLICY",
  BUSINESS_SERVERS: "BUSINESS_SERVERS",
  API_ACCOUNTS: "API_ACCOUNTS",
  ADMIN_ACCOUNTS: "ADMIN_ACCOUNTS",
  ENABLE_DISABLE_LOGIN: "ENABLE_DISABLE_LOGIN",
  SITES: "SITES",
  MONITORS: "MONITORS",
  NOTIFICATION_PROFILES: "NOTIFICATION_PROFILE",
  CUSTOM_ACTIONS: "CUSTOM_ACTIONS",
  RESOURCE_POOLS: "RESOURCE_POOLS",
};

export const DEFAULT_PROFILE_PATH_TYPE = {
  AZURE_FILES: "Azure Files",
  ANF: "ANF",
};

export const PERMISSIONS_LIST = {
  [`${MODULES_LIST.DASHBOARD}.${MODULES_LIST.OVERVIEW}`]: {
    [PERMISSIONS_TYPE.VIEW]: "DASHBOARD.OVERVIEW.read",
  },
  [`${MODULES_LIST.DASHBOARD}.${MODULES_LIST.TENANT_DETAILS}`]: {
    [PERMISSIONS_TYPE.VIEW]: "DASHBOARD.TENANT_DETAILS.read",
    [PERMISSIONS_TYPE.EDIT]: "DASHBOARD.TENANT_DETAILS.update",
  },
  [MODULES_LIST.LICENSES]: {
    [PERMISSIONS_TYPE.VIEW]: "LICENSES.read",
  },
  [MODULES_LIST.USER_SESSIONS]: {
    [PERMISSIONS_TYPE.VIEW]: "USER_SESSIONS.read",
  },
  [`${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.PERFORMANCE}`]: {
    [PERMISSIONS_TYPE.VIEW]: "USER_SESSIONS.PERFORMANCE.read",
  },
  [`${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.PROCESSES}`]: {
    [PERMISSIONS_TYPE.VIEW]: "USER_SESSIONS.PROCESSES.read",
    [PERMISSIONS_TYPE.DELETE]: "USER_SESSIONS.PROCESSES.delete",
  },
  [`${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.USER_LOGON_ACTIVITY}`]: {
    [PERMISSIONS_TYPE.VIEW]: "USER_SESSIONS.USER_LOGON_ACTIVITY.read",
  },
  [`${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.LOGOFF}`]:
    {
      [PERMISSIONS_TYPE.ACCESS]: "USER_SESSIONS.QUICK_ACTIONS.LOGOFF.access",
    },
  [MODULES_LIST.VIRTUAL_MACHINES]: {
    [PERMISSIONS_TYPE.VIEW]: "VIRTUAL_MACHINES.read",
  },
  [MODULES_LIST.PERFORMANCE]: {
    [PERMISSIONS_TYPE.VIEW]: "PERFORMANCE.read",
  },
  [`${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.PERFORMANCE}`]: {
    [PERMISSIONS_TYPE.VIEW]: "VIRTUAL_MACHINES.PERFORMANCE.read",
  },
  [`${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.PROCESSES}`]: {
    [PERMISSIONS_TYPE.VIEW]: "VIRTUAL_MACHINES.PROCESSES.read",
    [PERMISSIONS_TYPE.DELETE]: "VIRTUAL_MACHINES.PROCESSES.delete",
  },
  [`${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.ACTIVE_SESSIONS}`]: {
    [PERMISSIONS_TYPE.VIEW]: "VIRTUAL_MACHINES.ACTIVE_SESSIONS.read",
  },
  [`${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.USER_LOGON_ACTIVITY}`]: {
    [PERMISSIONS_TYPE.VIEW]: "VIRTUAL_MACHINES.USER_LOGON_ACTIVITY.read",
  },
  [`${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.CONNECT_TO_MACHINE}`]:
    {
      [PERMISSIONS_TYPE.ACCESS]:
        "VIRTUAL_MACHINES.QUICK_ACTIONS.CONNECT_TO_MACHINE.access",
    },
  [`${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.CONNECT_AS_TO_MACHINE}`]:
    {
      [PERMISSIONS_TYPE.ACCESS]:
        "VIRTUAL_MACHINES.QUICK_ACTIONS.CONNECT_AS_TO_MACHINE.access",
    },
  [`${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.REBOOT}`]:
    {
      [PERMISSIONS_TYPE.ACCESS]: "VIRTUAL_MACHINES.QUICK_ACTIONS.REBOOT.access",
    },
  [`${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.ENABLE_DISABLE_LOGIN}`]:
    {
      [PERMISSIONS_TYPE.ACCESS]:
        "VIRTUAL_MACHINES.QUICK_ACTIONS.ENABLE_DISABLE_LOGIN.access",
    },
  [MODULES_LIST.DATA_VOLUMES]: {
    [PERMISSIONS_TYPE.VIEW]: "DATA_VOLUMES.read",
  },
  [`${MODULES_LIST.DATA_VOLUMES}.${MODULES_LIST.SUMMARY}`]: {
    [PERMISSIONS_TYPE.VIEW]: "DATA_VOLUMES.SUMMARY.read",
  },
  [`${MODULES_LIST.DATA_VOLUMES}.${MODULES_LIST.DIRECTJOIN_PROPERTIES}`]: {
    [PERMISSIONS_TYPE.VIEW]: "DATA_VOLUMES.DIRECTJOIN_PROPERTIES.read",
    [PERMISSIONS_TYPE.EDIT]: "DATA_VOLUMES.DIRECTJOIN_PROPERTIES.update",
  },
  [`${MODULES_LIST.DATA_VOLUMES}.${MODULES_LIST.PERFORMANCE}`]: {
    [PERMISSIONS_TYPE.VIEW]: "DATA_VOLUMES.PERFORMANCE.read",
  },
  [`${MODULES_LIST.SECURITY}.${MODULES_LIST.SECURITY_ALERTS}`]: {
    [PERMISSIONS_TYPE.VIEW]: "SECURITY.SECURITY_ALERTS.read",
  },
  [`${MODULES_LIST.SECURITY}.${MODULES_LIST.RECOMMENDATIONS}`]: {
    [PERMISSIONS_TYPE.VIEW]: "SECURITY.RECOMMENDATIONS.read",
  },
  [MODULES_LIST.LOGS]: {
    [PERMISSIONS_TYPE.VIEW]: "LOGS.read",
  },
  [`${MODULES_LIST.LOGS}.${MODULES_LIST.ADMIN_ACTIVITY}`]: {
    [PERMISSIONS_TYPE.VIEW]: "LOGS.ADMIN_ACTIVITY.read",
  },
  [`${MODULES_LIST.LOGS}.${MODULES_LIST.USER_LOGON_ACTIVITY}`]: {
    [PERMISSIONS_TYPE.VIEW]: "LOGS.USER_LOGON_ACTIVITY.read",
  },
  [MODULES_LIST.ALERTS]: {
    [PERMISSIONS_TYPE.VIEW]: "ALERTS.read",
    [PERMISSIONS_TYPE.EDIT]: "ALERTS.update",
    [PERMISSIONS_TYPE.CREATE]: "ALERTS.create",
    [PERMISSIONS_TYPE.DELETE]: "ALERTS.delete",
  },
  [MODULES_LIST.MONITORS]: {
    [PERMISSIONS_TYPE.VIEW]: "MONITORS.read",
    [PERMISSIONS_TYPE.EDIT]: "MONITORS.update",
    [PERMISSIONS_TYPE.CREATE]: "MONITORS.create",
    [PERMISSIONS_TYPE.DELETE]: "MONITORS.delete",
    [PERMISSIONS_TYPE.ACCESS]: "MONITORS.access",
  },
  [MODULES_LIST.IMAGES]: {
    [PERMISSIONS_TYPE.CREATE]: "IMAGES.create",
    [PERMISSIONS_TYPE.VIEW]: "IMAGES.read",
    [PERMISSIONS_TYPE.EDIT]: "IMAGES.update",
    [PERMISSIONS_TYPE.DELETE]: "IMAGES.delete",
    [PERMISSIONS_TYPE.ACCESS]: "IMAGES.access",
  },
  [MODULES_LIST.SPOT_GROUPS]: {
    [PERMISSIONS_TYPE.CREATE]: "SPOT_GROUPS.create",
    [PERMISSIONS_TYPE.VIEW]: "SPOT_GROUPS.read",
    [PERMISSIONS_TYPE.EDIT]: "SPOT_GROUPS.update",
    [PERMISSIONS_TYPE.DELETE]: "SPOT_GROUPS.delete",
  },
  [MODULES_LIST.NOTIFICATION_PROFILES]: {
    [PERMISSIONS_TYPE.CREATE]: "NOTIFICATION_PROFILE.create",
    [PERMISSIONS_TYPE.VIEW]: "NOTIFICATION_PROFILE.read",
    [PERMISSIONS_TYPE.EDIT]: "NOTIFICATION_PROFILE.update",
    [PERMISSIONS_TYPE.DELETE]: "NOTIFICATION_PROFILE.delete",
  },
  [MODULES_LIST.CONNECTIONS]: {
    [PERMISSIONS_TYPE.CREATE]: "CONNECTIONS.create",
    [PERMISSIONS_TYPE.VIEW]: "CONNECTIONS.read",
    [PERMISSIONS_TYPE.EDIT]: "CONNECTIONS.update",
    [PERMISSIONS_TYPE.DELETE]: "CONNECTIONS.delete",
  },
  [MODULES_LIST.DEVICE_IMAGES]: {
    [PERMISSIONS_TYPE.CREATE]: "DEVICE_IMAGES.create",
    [PERMISSIONS_TYPE.VIEW]: "DEVICE_IMAGES.read",
    [PERMISSIONS_TYPE.EDIT]: "DEVICE_IMAGES.update",
    [PERMISSIONS_TYPE.DELETE]: "DEVICE_IMAGES.delete",
  },
  [MODULES_LIST.PROVISIONING_POLICY]: {
    [PERMISSIONS_TYPE.CREATE]: "PROVISIONING_POLICY.create",
    [PERMISSIONS_TYPE.VIEW]: "PROVISIONING_POLICY.read",
    [PERMISSIONS_TYPE.EDIT]: "PROVISIONING_POLICY.update",
    [PERMISSIONS_TYPE.DELETE]: "PROVISIONING_POLICY.delete",
  },
  [MODULES_LIST.BUSINESS_SERVERS]: {
    [PERMISSIONS_TYPE.CREATE]: "BUSINESS_SERVERS.create",
    [PERMISSIONS_TYPE.VIEW]: "BUSINESS_SERVERS.read",
    [PERMISSIONS_TYPE.EDIT]: "BUSINESS_SERVERS.update",
    [PERMISSIONS_TYPE.DELETE]: "BUSINESS_SERVERS.delete",
  },
  [MODULES_LIST.API_ACCOUNTS]: {
    [PERMISSIONS_TYPE.CREATE]: "API_ACCOUNTS.create",
    [PERMISSIONS_TYPE.VIEW]: "API_ACCOUNTS.read",
    [PERMISSIONS_TYPE.EDIT]: "API_ACCOUNTS.update",
    [PERMISSIONS_TYPE.DELETE]: "API_ACCOUNTS.delete",
  },
  [MODULES_LIST.SITES]: {
    [PERMISSIONS_TYPE.CREATE]: "SITES.create",
    [PERMISSIONS_TYPE.VIEW]: "SITES.read",
    [PERMISSIONS_TYPE.EDIT]: "SITES.update",
    [PERMISSIONS_TYPE.DELETE]: "SITES.delete",
  },
  [MODULES_LIST.ADMIN_ACCOUNTS]: {
    [PERMISSIONS_TYPE.VIEW]: "ADMIN_ACCOUNTS.read",
  },
  [MODULES_LIST.CUSTOM_ACTIONS]: {
    [PERMISSIONS_TYPE.CREATE]: "CUSTOM_ACTIONS.create",
    [PERMISSIONS_TYPE.VIEW]: "CUSTOM_ACTIONS.read",
    [PERMISSIONS_TYPE.EDIT]: "CUSTOM_ACTIONS.update",
    [PERMISSIONS_TYPE.DELETE]: "CUSTOM_ACTIONS.delete",
  },
  [MODULES_LIST.RESOURCE_POOLS]: {
    [PERMISSIONS_TYPE.VIEW]: "RESOURCE_POOLS.read",
  },
};

export const ACCOUNTS_TABS = {
  ACCOUNTS: "accounts",
  ROLES: "roles",
  ACTIVITY_LOGS: "activityLogs",
};

export const SPOT_GROUP_LIST_ICONS_TOOLTIP = {
  EDIT: "Edit Spot Group",
  UPDATE: "Update Spot Group",
  ASSIGN: "Assign Users",
  DELETE: "Delete Spot Group",
  CANCEL_UPDATE: "Cancel Scheduled Update",
};

export const SITES_FIELD_TOOLTIP = {
  ENABLED_CREATE_SITE:
    "Enabling a site makes it available for provisioning Spot Groups or cloud pcs",
  LOCATION: "Azure Region where resources will be created",
  H5ADDRESS: "Spot PC HTML5 Gateway Address",
  PROFILEPATH: "File Share to Store User Profiles and Data",
  DIRECTORYTYPE: "The machines will be joined to the selected Directory Type",
  SUBSCRIPTIONID: "Spot PC Subscription ID where Spot PC Resources are created",
  HYPERVISORTYPE:
    "Azure for Azure Virtual Desktop and Cloud PC for Windows 365",
  MAXAUTOMATION:
    "Defines the maximum number of concurrent processes Spot PC automation will run to complete workflows. Default value is 20, values above 100 should be carefully considered for effectiveness.",
  ADDRESSRANGE:
    "Private IP will be assigned to the Resources in Virtual Network from this Address Space",
  PRIMARYDNS: "This shows the IP of the DNS Server",
  SECONDARYDNS: "This shows the IP of the DNS Server",
  RECOVERYSERVICES:
    "Recovery Services Vault stores the backups and recovery points created over time",
};

export const ALERT_CONDITIONS = [
  {
    name: "> (greater than)",
    value: "GT",
    symbol: ">",
  },
  {
    name: "< (less than)",
    value: "LT",
    symbol: "<",
  },
];

export const OCCURRENCE_PATTERN_VALUES = {
  ONCE: "ONCE",
  CONTINUOUS: "CONTINUOUS",
};

export const OCCURRENCE_PATTERNS = [
  {
    name: "Once",
    value: OCCURRENCE_PATTERN_VALUES.ONCE,
  },
  {
    name: "Continuously",
    value: OCCURRENCE_PATTERN_VALUES.CONTINUOUS,
  },
];

export const OCCURRENCE_PERIODS = [
  {
    name: "1 minute",
    value: 60,
    _frequency: "For 1 minute",
  },
  {
    name: "5 minutes",
    value: 300,
    _frequency: "For 5 minutes",
  },
  {
    name: "10 minutes",
    value: 600,
    _frequency: "For 10 minutes",
  },
  {
    name: "15 minutes",
    value: 900,
    _frequency: "For 15 minutes",
  },
  {
    name: "30 minutes",
    value: 1800,
    _frequency: "For 30 minutes",
  },
  {
    name: "1 hour",
    value: 3600,
    _frequency: "For 1 hour",
  },
  {
    name: "2 hours",
    value: 7200,
    _frequency: "For 2 hours",
  },
  {
    name: "4 hours",
    value: 14400,
    _frequency: "For 4 hours",
  },
];

export const EMAIL_LIST_NOTIFICATIONS_TYPES = {
  WARNING: {
    templateGroup: "ALERT_TEMPLATE_GROUP_WARNING",
    name: "WARNING",
  },
  NORMAL: {
    templateGroup: "ALERT_TEMPLATE_GROUP_NORMAL",
    name: "NORMAL",
  },
  CRITICAL: {
    templateGroup: "ALERT_TEMPLATE_GROUP_CRITICAL",
    name: "CRITICAL",
  },
};

export const MONITOR_TYPES = {
  PERFORMANCE: {
    key: "PERFORMANCE",
  },
  METRIC: {
    key: "METRIC",
  },
};

export const MONITOR_LIST_TABS = {
  userDefined: "userDefined",
  default: "default",
};

export const APPLICATION_CONTEXTS = {
  ORGANIZATION: "organization",
  TENANT: "tenant",
};

export const SPOT_GROUP_IMAGE_SET_HISTORY_COLUMNS = [
  {
    header: "Name",
    accessor: "imageSetName",
    id: "imageSetName",
    identityColumn: true,
  },
  {
    header: "Version",
    accessor: "imageSetVerion",
    id: "imageSetVerion",
  },
  {
    header: "Release Method",
    accessor: "releaseMethod",
    id: "releaseMethod",
  },
  {
    header: "Scheduled on",
    accessor: "releaseScheduledTimeUTC",
    id: "releaseScheduledTimeUTC",
    cell: (row) =>
      row.value && row.value !== "--" ? (
        <div>{moment(row.value).format("DD MMM YYYY hh:mm A")}</div>
      ) : (
        <>{"--"}</>
      ),
  },
  {
    header: "Requested By",
    accessor: "requestedBy",
    id: "requestedBy",
  },
  {
    header: "Requested on",
    accessor: "requestedDateTime",
    id: "requestedDateTime",
    cell: (row) =>
      row.value && row.value !== "--" ? (
        <div>{moment(row.value).format("DD MMM YYYY hh:mm A")}</div>
      ) : (
        <>{"--"}</>
      ),
  },
];

export const HYPERVISOR_DETAILS = {
  HOSTS: "Hosts",
  CLUSTERS: "Clusters",
};

export const HYPERVISOR_TYPES = {
  CLOUDPC: "CloudPc",
  VCENTER: "vCenter",
  UNMANAGED_HYPERVISOR: "UnmanagedHypervisor",
};
export const RESOURCE_POOL_TYPE = {
  VDI: "Vdi",
};

export const DEVELOPMENT_ENVIRONMENT = "development";
