/* eslint-disable no-case-declarations */
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import propTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  ACTION_REQUIRED_TOOLTIP_TEXT,
  mapPoolsTagLabel,
} from "../../dashboard/components/pools/utils/poolStatusMap";
import { PoolTag } from "../../dashboard/components/tags/PoolTag";
import { TagIndicator } from "../../dashboard/components/tags/TagIndicator";
import { TenantsTag } from "../../dashboard/components/tags/TenantsTag";
import { MODULES_LIST, PERMISSIONS_LIST, PERMISSIONS_TYPE } from "../constants";
import useCTA from "../hooks/useCTA";
import useCurrentMachine from "../hooks/useCurrentMachine";
import useCurrentPool from "../hooks/useCurrentPool";
import useCurrentTenant from "../hooks/useCurrentTenant";
import { formatLabelToCamelCase, getIdentityId } from "../utils";
import GenericPageHeader from "./GenericPageHeader";
import OptionsMenuDropdownButton from "./OptionsMenuDropdownButton";
import VeneerButton from "./VeneerButton";

const PREFIX = "header";

const classes = {
  container: `${PREFIX}-container`,
  innerContainer: `${PREFIX}-innerContainer`,
};

const StyledHeaderContainer = styled("div")(() => ({
  [`&.${classes.container}`]: {
    padding: "24px 32px 0px 32px",
    marginTop: "70px",
    marginBottom: "10px",
  },
  [`& .${classes.innerContainer}`]: {
    maxWidth: "1280px",
    margin: "0 auto",
  },
}));

const Header = ({ hideActions = false }) => {
  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const currentMachine = useCurrentMachine();
  const currentPool = useCurrentPool();
  const { data, shouldRender } = useCTA();
  const location = useLocation();
  const { tenants } = useSelector((state) => state.partners);
  const { selectedIdentity } = useSelector((state) => state.identities);

  const { loadingPoolDetails } = useSelector(
    (state) => state.adminActions?.pools,
  );

  const isCTAEnabled = shouldRender && !!data?.headerProps?.tenant;
  const tenantToUse = isCTAEnabled ? data.headerProps.tenant : currentTenant;
  const tenantId = tenantToUse?.id || "";
  const currentPath = location.pathname.split("?")[0];
  const typeAddSite = location.pathname.replace("/sites/action/create/", "");

  const tagAddSite = {
    registeredDevices: "Registered devices",
    vmware: "VMware",
    win365: "Windows 365",
  };

  const tagLabel = tagAddSite[typeAddSite];

  const { tenantId: tenantIdOnboarding } = useSelector(
    (state) => state.onboarding,
  );
  const onboardingBrokerFqdn = useMemo(() => {
    const tenantsOrg = tenants[getIdentityId(selectedIdentity)] || [];
    const selectedTenant = tenantsOrg.filter(
      (i) =>
        i.id.replace("/tenants/", "").toLowerCase() ===
        tenantIdOnboarding.toLowerCase(),
    );
    return selectedTenant[0]?.brokerFqdn;
  }, [selectedIdentity, tenantIdOnboarding, tenants]);

  const displayNameOnboarding = useSelector(
    (state) => state.onboarding?.tenant[tenantIdOnboarding]?.displayName,
  );

  const handleViewTenantDetailsClick = () => {
    const cleanedTenantId = tenantId.replace("/tenants/", "");
    history.push(`/tenants/edit/${cleanedTenantId}`);
  };

  const urlHeaderMap = [
    { url: "/admin", headerType: "Tenants" },
    { url: "/onboarding.*", headerType: "<onboardingTitle>" },
    { url: "/selectOnboardingWizard", headerType: "Add tenant" },
    { url: "/tenants/edit/.*", headerType: "<tenantDetail>" },
    { url: "/admin/manage/accounts", headerType: "Accounts" },
    { url: "/dashboard/.*", headerType: "<tenantName>" },
    { url: "/dashboard/machines/.*/.*", headerType: "<machineName>" },
    { url: "/dashboard/pools/.*/.*", headerType: "<poolName>" },
    { url: "/dashboard/pools/progress", headerType: "<poolName>" },
    { url: "/dashboard/pools/addPool/.*", headerType: "Add Pool" },
    { url: "/sites", headerType: "Sites" },
    { url: "/sites/action/edit", headerType: "<sites>" },
    { url: "/sites/action/create/.*", headerType: "<createSite>" },
    { url: "/settings/general", headerType: "Settings" },
    { url: "/settings/licenses", headerType: "Settings" },
    { url: "/extensibility/accounts", headerType: "API accounts" },
  ];
  // sort by the longest url first
  urlHeaderMap.sort((a, b) => b.url.length - a.url.length);

  let matchingUrl;
  // If the url map item is regex, try to match the regex
  matchingUrl = urlHeaderMap.find((b) =>
    new RegExp(b.url.toLowerCase()).test(currentPath.toLowerCase()),
  );

  // Define elements based on the type
  let displayTitle,
    displaySubtitle,
    actions,
    displayTag,
    copyOptions,
    statusTooltipText,
    loadingTitle = false;

  const isOnboardingResume = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const typeParam = searchParams.get("resumeOnboarding");
    return typeParam === "true";
  }, [location.search]);

  switch (matchingUrl?.headerType) {
    // Tenant details and tenant name are the same, except for the hideActions
    case "<onboardingTitle>":
      if (
        isOnboardingResume &&
        (displayNameOnboarding === "" || displayNameOnboarding === undefined)
      ) {
        loadingTitle = true;
      } else {
        const addTenant = "Add tenant";
        loadingTitle = false;
        displayTitle =
          tenantIdOnboarding || isOnboardingResume
            ? displayNameOnboarding
            : addTenant;

        if (displayTitle === addTenant) {
          displaySubtitle = null;
          displayTag = null;
          copyOptions = {};
        } else {
          displaySubtitle = "Copy connection URL";
          displayTag = <TenantsTag status={"pending"} />;
          copyOptions = {
            allowed: true,
            message: "Copied to the clipboard",
            value: onboardingBrokerFqdn,
          };
        }
      }
      break;
    case "<tenantName>":
    case "<tenantDetail>":
      displayTitle = tenantToUse?.displayName;
      displaySubtitle = "Copy connection URL";
      actions = (
        <Grid container alignItems="center" justifyContent="end" spacing={1}>
          <Grid item>
            <VeneerButton
              appearance="secondary"
              onClick={handleViewTenantDetailsClick}
            >
              View details
            </VeneerButton>
          </Grid>
          <Grid item>
            <OptionsMenuDropdownButton
              title="Manage"
              options={[
                {
                  title: "Sites",
                  permissionSet: [
                    PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][
                      PERMISSIONS_TYPE.VIEW
                    ],
                  ],
                  href: "/sites",
                },
                {
                  title: "API Accounts",
                  permissionSet: [
                    PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][
                      PERMISSIONS_TYPE.VIEW
                    ],
                  ],
                  href: "/extensibility/accounts",
                },
              ]}
            />
          </Grid>
        </Grid>
      );
      const tenantStatus = formatLabelToCamelCase(tenantToUse?.status);
      if (tenantStatus) {
        displayTag = <TenantsTag status={tenantStatus} />;
      }
      copyOptions = {
        allowed: true,
        message: "Broker FQDN copied to clipboard!",
        value: tenantToUse?.brokerFqdn,
      };
      break;
    case "<machineName>":
      displayTitle = currentMachine?.name;
      break;
    case "<createSite>":
      displayTitle = "Add site";
      displayTag = <TagIndicator label={`Type: ${tagLabel}`} type="primary" />;
      break;
    case "<poolName>":
      const poolStatus = formatLabelToCamelCase(currentPool?.status);
      const knownStatus = mapPoolsTagLabel(poolStatus);
      const isActionRequired = knownStatus === "Action required";

      statusTooltipText = isActionRequired
        ? ACTION_REQUIRED_TOOLTIP_TEXT[poolStatus]
        : undefined;
      displayTitle = currentPool?.name;
      if (poolStatus) {
        displayTag = <PoolTag status={poolStatus} />;
      }
      break;
    case "<sites>":
      displayTitle = "";
      break;
    // ... other elements that has specific names
    default:
      displayTitle = matchingUrl?.headerType;
      displaySubtitle = null;
      actions = null;
      hideActions = true;
      displayTag = null;
      copyOptions = {};
      statusTooltipText = undefined;
  }

  // Other specific conditions
  if (matchingUrl?.headerType === "<tenantDetail>") {
    hideActions = true;
  }

  const isGenericHeaderLoading = loadingPoolDetails;

  return (
    <StyledHeaderContainer className={classes.container}>
      <div className={classes.innerContainer}>
        <GenericPageHeader
          loadingTitle={loadingTitle}
          loading={isGenericHeaderLoading}
          title={displayTitle}
          subtitle={displaySubtitle}
          tagComponent={displayTag}
          tooltipText={statusTooltipText}
          copyOptions={{
            ...copyOptions,
          }}
        >
          {!hideActions && actions}
        </GenericPageHeader>
      </div>
    </StyledHeaderContainer>
  );
};

Header.propTypes = {
  hideActions: propTypes.bool,
};

export default Header;
