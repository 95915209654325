import React from "react";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import { componentLoader } from "../../common/components/SuspenseComponent";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../../common/constants";
/*------Sites Components------*/
const SitesList = React.lazy(() =>
  componentLoader(() => import("./components/SitesList")),
);

const EditSitesContainer = React.lazy(() =>
  componentLoader(() => import("./components/EditSitesContainer")),
);

const CreateSitesContainer = React.lazy(() =>
  componentLoader(() => import("./components/CreateSitesContainer")),
);

const CreateWin365SiteContainer = React.lazy(() =>
  componentLoader(() => import("./components/win365/CreateWin365Site")),
);

const CreateVMwareSiteContainer = React.lazy(() =>
  componentLoader(() => import("./components/vmware/CreateVMwareSite")),
);

const CreateRegisteredDevicesSiteContainer = React.lazy(() =>
  componentLoader(
    () =>
      import("./components/vmRegisteredDevices/CreateRegisteredDevicesSite"),
  ),
);

const DeleteSites = React.lazy(() =>
  componentLoader(() => import("./components/DeleteSites")),
);

const SitesRoutes = [
  {
    path: "/sites",
    authRequired: true,
    title: "Sites",
    component: ParentRoute,
    routeTitleComponent: () => (
      <DashBoardRouteTitle title="Sites" showQuickActions={true} />
    ),
    layoutStyle: LAYOUTS.MENU_SCREEN,

    routes: [
      {
        path: "/sites",
        exact: true,
        title: "Sites",
        component: () => (
          <ProtectedRouteSuspenseContainer component={SitesList} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.VIEW],
        ],
      },
      {
        path: "/sites/action/create/win365",
        exact: true,
        title: "Creating Windows 365 Cloud PC Site",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateWin365SiteContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        path: "/sites/action/create/vmware",
        exact: true,
        title: "Creating vSphere Site",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateVMwareSiteContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        path: "/sites/action/create/registeredDevices",
        exact: true,
        title: "Creating Registered Devices Site",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateRegisteredDevicesSiteContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        path: "/sites/action/retry/:siteName",
        exact: true,
        title: "Create",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateSitesContainer}
            retry={true}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        path: "/sites/action/edit/:siteName?",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditSitesContainer} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.EDIT],
        ],
      },
      {
        path: "/sites/action/delete",
        exact: true,
        title: "Delete",
        component: () => (
          <ProtectedRouteSuspenseContainer component={DeleteSites} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.DELETE],
        ],
      },
    ],
  },
];

export default SitesRoutes;
