import { Grid, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { getGroupMembers } from "../admin-actions/deschutes/deschutesSlice";
import { Notification } from "./components/Notification";
import MultiSelectAutocompleteField from "./components/form/MultiSelectAutocompleteField";
import SelectInput from "./components/form/SelectInput";
import { ALL_CUSTOMERS, GROUPS_API_SIZE, HTTP_METHODS } from "./constants";
import HTTPClient from "./servicers/httpClient";
import { getIdentityId, getTenantId } from "./utils";
import { required } from "./validators";
let controller = null;

const SelectGroupsPagination = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );
  const { tenantId } = useSelector((state) => state.onboarding);
  const { loadingGroupMembers, groupMembers } = useSelector(
    (state) => state.adminActions.deschutes,
  );
  const {
    initialValues,
    formName,
    dataTestIdPrefix,
    showUserAssignment,
    maxQuantity,
    securityEnabled,
    setFormValues,
    onChange,
    description,
  } = props;
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [skipToken, setSkipToken] = useState("");
  const [paginatedGroupOptions, setPaginatedGroupOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupsWithNoLicneses, setGroupsWithNoLicneses] = useState([]);
  const [licensesLoading, setLicensesLoading] = useState(false);
  const { availableWin365Licenses } = useSelector(
    (state) => state.dashboard.tenant,
  );

  const getGroupsAPi = useCallback(
    async (searchTerm) => {
      setLoading(true);
      try {
        if (controller) {
          controller.abort();
        }
        controller = new AbortController();
        const signal = controller.signal;
        let getGroupsURL = null;
        if (selectedPartner !== ALL_CUSTOMERS) {
          getGroupsURL = `/organizations/${getIdentityId(selectedIdentity)}/tenants/${getTenantId(
            selectedPartner,
          )}/groups?`;
        } else {
          getGroupsURL = `/organizations/${getIdentityId(selectedIdentity)}/tenants/${tenantId}/groups?`;
        }
        let filter = "";
        if (skipToken) {
          filter = `${filter}skiptoken=${skipToken}`;
        }
        filter = `${filter}&query=`;
        filter = `${filter}${encodeURIComponent(`$top=${GROUPS_API_SIZE}&`)}`;
        if (securityEnabled) {
          filter = `${filter}${encodeURIComponent(
            `$filter=securityEnabled%20eq%20${securityEnabled} and not(startswith(displayName, 'InstallAnywareAgent'))&$count=true`,
          )}${encodeURIComponent("&")}`;
        }
        if (searchTerm) {
          filter = `${filter}${encodeURIComponent(`$search="displayName:${searchTerm}"`)}`;
        }
        const response = await new HTTPClient({
          endpoint: `${getGroupsURL}${filter}`,
          method: HTTP_METHODS.GET,
          signal,
        }).callAuthorizedAPI();
        const data = response.data.data;
        setSkipToken(data.odataNextLink);

        setPaginatedGroupOptions((currentOptions) => [
          ...currentOptions,
          ...data.value.map((g) => ({
            ...g,
            name: g.displayName,
            value: g.groupId,
          })),
        ]);
      } catch (error) {
        console.error("Failed to fetch groups:", error);
      } finally {
        setLoading(false);
      }
    },
    [securityEnabled, selectedIdentity, selectedPartner, skipToken, tenantId],
  );

  const getGroupsLicenses = useCallback(async () => {
    setLicensesLoading(true);
    try {
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();
      const signal = controller.signal;
      let tenantIdToUse = tenantId;
      let groupIds = selectedGroups.map((g) => g.value).join(",");
      if (selectedPartner !== ALL_CUSTOMERS) {
        tenantIdToUse = getTenantId(selectedPartner);
      }
      let getGroupsLicensesURL = `/organizations/${getIdentityId(selectedIdentity)}/tenants/${tenantIdToUse}/groups/win365Licenses`;
      let queryParams = `?groupIds=${groupIds}`;
      const response = await new HTTPClient({
        endpoint: `${getGroupsLicensesURL}${queryParams}`,
        method: HTTP_METHODS.GET,
        signal,
      }).callAuthorizedAPI();
      const data = response.data.data;
      const groupsWithNoLicensesButUsers = data
        .filter(
          (group) =>
            group.numUsersInGroupWithLicense === 0 && group.numUsersInGroup > 0,
        )
        .map((group) => ({
          name: group.groupDisplayName,
          groupId: group.groupId,
        }));
      setGroupsWithNoLicneses(groupsWithNoLicensesButUsers);
    } catch (error) {
      console.error("Failed to fetch groups licenses:", error);
    } finally {
      setLicensesLoading(false);
    }
  }, [selectedGroups]);

  useEffect(() => {
    if (selectedGroups?.length > 0) {
      getGroupsLicenses(selectedGroups);
    }
  }, [selectedGroups]);

  useEffect(() => {
    if (selectedIdentity && selectedPartner) {
      if (
        (selectedPartner === ALL_CUSTOMERS && tenantId) ||
        selectedPartner !== ALL_CUSTOMERS
      ) {
        getGroupsAPi();
      }
    }
  }, [selectedIdentity, selectedPartner, tenantId, getGroupsAPi]);

  useEffect(() => {
    if (initialValues?.group && showUserAssignment) {
      const tenantId = getTenantId(selectedPartner);
      const organizationId = getIdentityId(selectedIdentity);
      dispatch(
        getGroupMembers({
          selectedTenant: tenantId,
          organizationId,
          groupIds: initialValues.group.map((g) => g.value),
        }),
      );
    }
  }, []);

  // useEffect(() => {
  //   if (initialValues.group) {
  //     setSelectedGroups(initialValues.group);
  //   }
  // }, [initialValues.group]);

  useEffect(() => {
    if (
      initialValues &&
      initialValues.members &&
      initialValues.members.length > 0
    ) {
      setSelectedMembers(initialValues.members);
    }
  }, [initialValues.members]);

  useEffect(() => {
    const selectedGroups = paginatedGroupOptions.filter((group) =>
      selectedValues.includes(group.value),
    );
    setSelectedGroups(selectedGroups);
    setFormValues((values) => ({ ...values, selectedGroups: selectedGroups }));
    if (onChange) {
      onChange(selectedGroups);
    }
    if (selectedGroups && selectedGroups.length > 0 && showUserAssignment) {
      const tenantId = getTenantId(selectedPartner);
      const organizationId = getIdentityId(selectedIdentity);
      dispatch(
        getGroupMembers({
          selectedTenant: tenantId,
          organizationId,
          groupIds: selectedGroups.map((g) => g.value),
        }),
      );
    }
  }, [selectedValues]);

  const onSelectionSubmit = async (values) => {
    props.onSubmit({ ...values, allGroups: paginatedGroupOptions }, formName);
  };

  const mapOptionsToSelect = (options) => {
    return options
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((g) => ({
        ...g,
        label: g.name,
        value: g.value,
      }));
  };

  return (
    <>
      <Form
        onSubmit={onSelectionSubmit}
        initialValues={{
          members: selectedMembers,
          group: selectedGroups,
        }}
        validate={(values) => {
          const valid = !!values.group && values.group?.length > 0;
          if (props.onFormValidityChange) {
            props.onFormValidityChange(valid, formName);
          }
        }}
        render={(formMeta) => {
          const form = formMeta.form;
          return (
            <form onSubmit={formMeta.handleSubmit} ref={ref}>
              <Grid container sx={{ marginBottom: "20px" }}>
                <Grid item xs={12}>
                  {description && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: "5px",
                        color: theme.palette.foreground.light,
                      }}
                    >
                      <p>{description}</p>
                    </Grid>
                  )}
                  <Field
                    name="group"
                    id="group"
                    render={() => (
                      <SelectInput
                        onChange={({ value: selectedValue }) => {
                          const valueCopy = [...selectedValues];
                          const isOptionSelected =
                            valueCopy.includes(selectedValue);
                          if (isOptionSelected) {
                            const index = valueCopy.indexOf(selectedValue);
                            valueCopy.splice(index, 1);
                          } else {
                            valueCopy.push(selectedValue);
                          }
                          setSelectedValues(valueCopy);
                        }}
                        data-testid={`${dataTestIdPrefix}selected-group-field`}
                        label="Entra groups"
                        validate={required}
                        value={selectedValues || ""}
                        onClear={() => setSelectedValues([])}
                        options={mapOptionsToSelect(paginatedGroupOptions)}
                        isSearchable
                        multiple
                        loading={loading}
                        visibleOptions={7}
                      />
                    )}
                  />
                </Grid>
                {showUserAssignment &&
                  form.getState().values.group &&
                  form.getState().values.group.length > 0 && (
                    <Grid item xs={12}>
                      <Field
                        name="members"
                        id="members"
                        disabledNewSelection={
                          selectedMembers.length === maxQuantity
                        }
                        disabledNewSelectionTooltip={
                          "Please increase the License count to choose more members."
                        }
                        selectedSelectionTooltip={
                          "Already assigned members can not be removed"
                        }
                        onChange={(values) => {
                          setSelectedMembers(values);
                        }}
                        InputProps={{
                          selectedReadonlyData: props.selectedReadonlyMembers,
                        }}
                        data-testid={`${dataTestIdPrefix}members`}
                        component={MultiSelectAutocompleteField}
                        validate={required}
                        options={groupMembers ? groupMembers : []}
                        label={"Select Members"}
                        loading={loadingGroupMembers}
                      />
                    </Grid>
                  )}
                {/* <Grid item xs={12}>
                  <Checkbox
                    defaultChecked
                    label="Assign a HP Anyware license for these users"
                  />
                </Grid> */}
                {!licensesLoading &&
                  groupsWithNoLicneses?.length > 0 &&
                  selectedGroups?.length > 0 &&
                  availableWin365Licenses !== 0 && (
                    <Grid item xs={12}>
                      <br />
                      <Notification
                        closeButton={false}
                        title={
                          <>
                            <p>
                              <p>
                                The following groups or users don&apos;t have
                                Windows 365 licenses. Machines will be created
                                automatically once the necessary licenses are
                                assigned:
                              </p>
                              <br />
                              {groupsWithNoLicneses.map((group) => (
                                <p key={group.groupId}>{group.name}</p>
                              ))}
                            </p>
                          </>
                        }
                        type="warning"
                      />
                    </Grid>
                  )}
              </Grid>
            </form>
          );
        }}
      />
    </>
  );
});

SelectGroupsPagination.displayName = "SelectGroupsPagination";

export default SelectGroupsPagination;

SelectGroupsPagination.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onFormValidityChange: PropTypes.func,
  loading: PropTypes.bool,
  showUserAssignment: PropTypes.bool,
};
SelectGroupsPagination.defaultProps = {
  initialValues: {},
  valueField: "value",
  dataTestIdPrefix: "",
  showUserAssignment: false,
  onFormValidityChange: () => {},
};
