import { Skeleton, styled } from "@mui/material";
import {
  IconDensityHigh,
  IconGear,
  IconLockAccount,
  IconPeople,
  SideMenuGroup,
  SideMenuItem,
} from "@veneer/core";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useMode } from "../../../../ModeProvider";
import { getColorScheme } from "../../../../theme";
import { cleanRoutePathName } from "../../../utils";
import { OrganizationModal } from "./organization-modal/OrganizationModal";
import { ORG_ROLES } from "../../../../common/constants";

const StyledSideMenuItem = styled(SideMenuItem)(({ theme }) => ({
  "& .vn-side-menu__item-link": {
    borderRadius: "360px !important",
    "&:hover": {
      color: `${theme.colors.foreground.default} !important`,
    },
  },
}));

const AccountsSidebar = styled("div")(() => ({
  "& .AccountsSidebar": {
    borderTopRightRadius: "360px",
    borderBottomRightRadius: "360px",
  },
}));

const routes = {
  admin: "/admin",
  accounts: "/admin/manage/accounts",
  roles: "/admin/manage/roles",
  activityLogs: "/admin/manage/activityLogs",
  settings: "/settings/general",
};

const Divider = ({ colors }) => {
  const isSideMenuOpen = useSelector((state) => state.sidebar.isOpen);
  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          width: isSideMenuOpen ? "287px" : "40px",
          height: "1px",
          backgroundColor: colors.primary.default + "19", // Use colors prop here
          margin: "8px auto",
        }}
      />
    </div>
  );
};

export const SidebarContent = () => {
  const { pathname } = useLocation();
  const actualPathName = cleanRoutePathName(pathname);
  const navCardRef = useRef();
  const modalCardRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );
  const identities = useSelector((state) => state.identities.identities);
  const selectedOrganization = identities?.find(
    (org) => org.id === selectedIdentity,
  );
  const organizationName = selectedOrganization?.name;
  const { organizationIdentity } = useSelector(
    (state) => state.organizationIdentity,
  );

  const { mode } = useMode();
  const colors = getColorScheme(mode);

  const handleOrganizationClick = () => {
    setIsModalOpen((e) => !e);
  };
  const handleClickOutside = useCallback((event) => {
    if (
      modalCardRef.current &&
      !modalCardRef.current.contains(event.target) &&
      navCardRef.current &&
      !navCardRef.current.contains(event.target)
    ) {
      setIsModalOpen(false);
    }
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
  useLayoutEffect(() => {
    if (navCardRef.current) {
      const rect = navCardRef.current.getBoundingClientRect();
      setModalPosition({ top: rect.top + rect.height, left: rect.left });
    }
  }, [isModalOpen]);

  const listItems = [
    {
      id: "tenants",
      name: "Tenants",
      link: routes.admin,
      Icon: <IconPeople />,
      isSelected: actualPathName === routes.admin,
    },
    {
      id: "admin",
      name: "Admin",
      link: "",
      Icon: <IconLockAccount />,
      isSelected: [
        routes.roles,
        routes.accounts,
        routes.activityLogs.toLowerCase(),
      ].includes(actualPathName),
      subOptions: [
        {
          id: "accounts",
          name: "Accounts",
          link: routes.accounts,
          isSelected: actualPathName === routes.accounts,
          hide: organizationIdentity?.role !== ORG_ROLES.ORG_ADMIN,
        },
        {
          id: "roles",
          name: "Roles",
          link: routes.roles,
          isSelected: actualPathName === routes.roles,
          hide: true,
        },
      ],
    },
  ];
  const fixedListItems = [
    {
      id: "settings",
      name: "Settings",
      link: "/settings/general",
      Icon: <IconGear />,
      isSelected: actualPathName === routes.settings,
      hide: organizationIdentity?.role !== ORG_ROLES.ORG_ADMIN,
    },
    // {
    //   id: "helpAndSupport",
    //   name: "Help and Support",
    //   link: "",
    //   Icon: <IconHelp />,
    //   isSelected: false,
    // },
  ];

  return (
    <>
      <OrganizationModal
        position={modalPosition}
        open={isModalOpen}
        ref={modalCardRef}
      />
      <div>
        <Divider colors={colors} />
        {organizationName ? (
          <SideMenuItem
            ref={navCardRef}
            label={organizationName}
            icon={<IconDensityHigh />}
            key={"organizationList"}
            id="organizationList"
            onClick={handleOrganizationClick}
          />
        ) : (
          <div
            style={{
              margin: 8,
              width: "calc(100% - 16px)",
              boxSizing: "border-box",
            }}
          >
            <Skeleton variant="text" width="100%" height={36} />
          </div>
        )}
        <Divider colors={colors} />
        <SideMenuGroup>
          {listItems.map((item) => {
            if (item.subOptions?.every((subOption) => subOption.hide)) {
              return <></>;
            } else if (
              item.subOptions &&
              item.subOptions.some((subOption) => !subOption.hide)
            ) {
              item.subOptions = item.subOptions.filter(
                (subOption) => !subOption.hide,
              );
              return (
                <SideMenuItem
                  key={item.id}
                  label={item.name}
                  icon={item.Icon}
                  selected={item.isSelected}
                  expanded={true}
                  onClick={() => {
                    return;
                  }}
                >
                  {item.subOptions.map((subOption) => {
                    return (
                      <AccountsSidebar key={subOption.id}>
                        <SideMenuItem
                          className="AccountsSidebar"
                          link={
                            <NavLink key={subOption.id} to={subOption.link} />
                          }
                          key={subOption.id}
                          label={subOption.name}
                          selected={subOption.isSelected}
                          url={subOption.link}
                        />
                      </AccountsSidebar>
                    );
                  })}
                </SideMenuItem>
              );
            }
            return (
              <SideMenuItem
                link={<NavLink key={item.id} to={item.link} />}
                key={item.id}
                label={item.name}
                icon={item.Icon}
                selected={item.isSelected}
                url={item.link}
              />
            );
          })}
        </SideMenuGroup>
      </div>
      <div>
        {fixedListItems.map((item) => {
          return (
            !item.hide && (
              <StyledSideMenuItem
                link={<NavLink key={item.id} to={item.link} exact />}
                key={item.id}
                to={item.link}
                label={item.name}
                icon={item.Icon}
                selected={item.isSelected}
                url={item.link}
                theme={{ colors }}
              />
            )
          );
        })}
      </div>
    </>
  );
};
