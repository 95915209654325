import { styled } from "@mui/material/styles";
import { Password } from "@veneer/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import CommonModal from "../../../common/components/CommonModal";
import TextInput from "../../../common/components/form/TextInput";
import VeneerButton from "../../../common/components/VeneerButton";

const CustomPassword = styled(Password)(({ theme }) => ({
  ["& label"]: {
    color: theme.palette.foreground.light,
  },
}));

const EditTenantAdCredentialsModal = ({
  isModalOpen,
  handleCloseModal,
  adCredentials,
  onConfirm,
}) => {
  const { loadingAdCredentials: loading } = useSelector(
    (state) => state.dashboard.tenant,
  );

  const { control, getValues } = useForm({
    mode: "all",
    defaultValues: {
      username: adCredentials,
    },
  });

  return (
    <div>
      <CommonModal
        id="edit-tenant-modal"
        title="Update AD details"
        open={isModalOpen}
        onClose={handleCloseModal}
        primaryText="Save"
      >
        <div>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextInput label="Username" disabled={loading} {...field} />
            )}
          />
        </div>

        <div style={{ marginTop: "16px" }}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <CustomPassword
                {...field}
                label={"Password"}
                separateLabel
                data-testid="onboarding-vCenterUrl-password"
              />
            )}
          />
          <VeneerButton
            appearance="primary"
            style={{ marginTop: "20px", marginRight: "12px" }}
            onClick={() => {
              onConfirm(getValues());
            }}
            disabled={loading}
          >
            {loading ? "Saving..." : "Confirm"}
          </VeneerButton>
          <VeneerButton
            appearance="secondary"
            style={{ marginTop: "20px" }}
            onClick={handleCloseModal}
            disabled={loading}
          >
            Cancel
          </VeneerButton>
        </div>
      </CommonModal>
    </div>
  );
};

export default EditTenantAdCredentialsModal;
