import styled from "@emotion/styled";
import { Group } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import { PROGRESS_STATUS, TENANT_PROGRESS } from "../../common/constants";
import { formatLabelToCamelCase } from "../../common/utils";
import { ProgressLogTag } from "../../dashboard/components/tags/ProgressLogTag";

const PREFIX = "ProgressLogStepCard";

const classes = {
  status: `${PREFIX}-status`,
  tag: `${PREFIX}-tag`,
  stepDescription: `${PREFIX}-stepDescription`,
  inProgress: `${PREFIX}-inProgress`,
};

const ProgressLogStepCardStyles = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: "6px",
  borderRadius: "5px",
  backgroundColor: theme.palette.background.container,
  border: `1px solid ${theme.palette.background.stroke}`,
  boxShadow: "none",

  [`.${classes.status}`]: {
    fontWeight: 700,
    border: 0,
    userSelect: "none",
  },

  [`.${classes.tag}`]: {
    borderRadius: "4px",
  },

  [`.${classes.stepDescription}`]: {
    width: "60%",
  },

  [`.${classes.inProgress}`]: {
    fontWeight: "bolder",
  },
}));

export const ProgressLogStepCard = ({
  currentStatus,
  title,
  // timestamp,
  noBorder = false,
}) => {
  const stepId = title.toLowerCase().split(" ").join("-");
  const statusId = currentStatus.toLowerCase().split(" ").join("-");
  const isInProgress = currentStatus === TENANT_PROGRESS.IN_PROGRESS;

  const camelCaseStatus = formatLabelToCamelCase(currentStatus);
  return (
    <ProgressLogStepCardStyles style={noBorder ? { border: "none" } : {}}>
      <Group>
        <ProgressLogTag
          status={currentStatus}
          data-testid={`${stepId}-${statusId}`}
        />
      </Group>
      <Group className={classes.stepDescription}>
        <span
          className={isInProgress ? classes.inProgress : ""}
          data-status={camelCaseStatus}
          data-testid={`${stepId}-step`}
        >
          {title}
        </span>
      </Group>
      {/*TODO: Uncomment the lines below when the backend supports timestamps for the progress log}
      <Group>
        <span>{timestamp ?? '-'}</span>
      </Group> */}
    </ProgressLogStepCardStyles>
  );
};

ProgressLogStepCard.propTypes = {
  currentStatus: PropTypes.oneOf(Object.values(PROGRESS_STATUS)).isRequired,
  title: PropTypes.string.isRequired,
  timestamp: PropTypes.string,
  customStatus: PropTypes.string,
};
