import { Grid, useTheme } from "@mui/material";
import { Button, IconCopy, useToast } from "@veneer/core";
import "@veneer/styles/typography/typography.css";
import PropTypes from "prop-types";
import React from "react";
import ShimmerLoader from "../shimmerLoader/ShimmerLoader";
import InputTooltip from "./form/InputTooltip";

const GenericPageHeader = ({
  loading,
  title,
  subtitle,
  tagComponent,
  tooltipText,
  children,
  copyOptions,
  loadingTitle,
}) => {
  const { addToast } = useToast();
  const { palette } = useTheme();

  const copyValue = (value) => {
    navigator.clipboard.writeText(value);
    const message = copyOptions?.message
      ? copyOptions.message
      : "Copied to the clipboard";

    addToast({
      text: message,
      type: "positive",
      timeout: 6,
      id: Date.now(),
    });
  };

  if (loading || loadingTitle) {
    return (
      <div>
        <ShimmerLoader type="largeText" width="150px" />
        {loading && <ShimmerLoader type="text" width="300px" />}
      </div>
    );
  }

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <h3>{title}</h3>
            </Grid>
          </Grid>
        </Grid>

        {!!children && <Grid item>{children}</Grid>}
      </Grid>

      {!!subtitle && (
        <Grid container>
          <Grid item alignSelf="center">
            <span
              style={{
                display: "block",
                margin: 0,
                padding: 0,
                color: palette.primary.main,
              }}
            >
              {subtitle}
            </span>
          </Grid>

          {!!copyOptions && copyOptions.allowed && (
            <Grid item ml={0.5}>
              <Button
                leadingIcon={<IconCopy />}
                onClick={() => copyValue(copyOptions.value || subtitle)}
                small
                appearance="ghost"
              />
            </Grid>
          )}
        </Grid>
      )}
      {!!tagComponent && (
        <Grid
          item
          sx={{ display: "flex", alignItems: "center", marginTop: "4px" }}
        >
          {tagComponent}
          {tooltipText && <InputTooltip tooltip={tooltipText} />}
        </Grid>
      )}
    </div>
  );
};

GenericPageHeader.propTypes = {
  loading: PropTypes.bool,
  loadingTitle: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tagComponent: PropTypes.node,
  children: PropTypes.node,
  copyOptions: PropTypes.shape({
    allowed: PropTypes.bool,
    message: PropTypes.string,
    btnColor: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default GenericPageHeader;
